.productivity-assessment {
  @extend %clearfix;
  font-size: ms(1);
  margin-top: 2em;

  header {
    margin-bottom: 2em;
  }

  h1 {
    text-align: left;
    margin-top: 0;
  }

  ul {
    font-weight: 300;
    list-style-image: url('/assets/img/check.svg');
    margin-top: 1em;
    line-height: 1.3;
  }

  ul.standard {
    list-style-image: unset;

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .illustration {
    @extend %clearfix;
    @include layout($narrow);
    @include span(4 of 4);

    @media (min-width: $m) {
      @include layout($medium);
      @include span(first 3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(first 6 of 12);
    }

    .intro {
      line-height: 1.5;
    }

    img {
      width: 200px;
      float: right;
      margin-left: 36px;
      filter: drop-shadow(10px 10px 16px #777777);
    }

    .template-details {
      @include layout($narrow);
      @include span(4 of 4);
    }
  }

  .text {
    @extend %clearfix;
    @include layout($narrow);
    @include span(4 of 4);

    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include pre(1 of 12);
      @include span(last 5 of 12);
    }
  }

  .customer-stories-testimonial {
    @extend %clearfix;
    .quote {
        font-size: ms(0);
        font-weight: 300;
        line-height: 1.2;

        blockquote {
          color: $colour--primary--dark;
        }

        @include layout($narrow);
        margin-right: gutter();
        margin-bottom: gutter();
        @include span(last 3 of 4);

        @media (min-width: $m) {
            @include layout($medium);
            margin-right: gutter();
            margin-bottom: gutter();
            @include span(last 5 of 6);
        }

        .position, .company {
            display: block;
            line-height: 1.5;
        }

        .position {
            color: $colour--grey;
            font-style: italic;
        }

        .company {
            color: $colour--quaternary--lighter;
        }
    }

    .avatar {
        margin-top: 1.5rem;
        filter: none;

        @include layout($narrow);
        margin-left: gutter();
        margin-bottom: gutter();
        @include span(first 0.75 of 4);

        @media (min-width: $m) {
            @include layout($medium);
            margin-left: gutter();
            margin-bottom: gutter();
            @include span(first 0.75 of 6);
        }
    }
  }

  .mobile.mobile { // Chained for specificity
    box-shadow: none;
  }

  .mobile {
    filter: drop-shadow(0px 0px 1em rgba(0,0,0,.1));
  }
}

.privacy-statement {
  margin-top: 0.5em;
}
