.avatar {
  display: block;
  border-radius: 50%;

  .article > & {
    margin-top: 1em;
    margin-bottom: 0.5em;

    @media (min-width: $xs) {
      @include layout($narrow);
      @include span(2 of 4);
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include span(2 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(4 of 12);
    }
  }
}
