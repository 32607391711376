.about-page {
  p {
    font-size: 1.2em;
  }
}
.values-div {
  width: 32%;
  padding: 20px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    width: 100% !important;
    border: 0px;
    margin: 0px;
    padding: 20px 10px;
  }
}

.our-values-div {
  h2 {
    margin: 0;
    padding-bottom: 20px;
  }
}

.values-div2 {
  width: 33%;
  padding: 10px;

  @media screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.values-image-div {
  padding-bottom: 10px;
}

.values-header {
  color: #4b174d;
  font-size: 1.6em;
}

.values-text {
  font-size: 1.2em;
  font-weight: 100;
}

.reclaro-awards-div {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-direction: column;

  .award-image-div {
    padding: 10px 0px;
    text-align: center;
    img {
      margin: auto;
      width: auto;
      max-height: 400px;
      border-radius: 10px;
      box-shadow: 0px 0px 5px #555;
    }
  }
}

@media screen and (max-width: 840px) {
  .reclaro-awards-div {
    flex-direction: column;
    justify-content: center;

    .award-image-div {
      display: flex;
      justify-content: center;
      padding: 10px 0px;
      text-align: justify;

      img {
        height: 100%;
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .reclaro-awards-div {
    .award-image-div {
      img {
        width: 100%;
      }
    }
  }
}

.vision-purpose-div {
  padding: 40px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media screen and (max-width: 840px) {
    flex-direction: column;
    justify-content: center;
  }

  .vp-child {
    // padding-top: 20px;
    flex: 1;

    // @media screen and (max-width: 840px) {
    //   text-align: center;
    // }

    img {
      max-width: 500px;
      height: auto;
      border-radius: 70% 34% 70% 29% / 30% 78% 30% 76% !important;

      @media screen and (max-width: 840px) {
        margin: auto;
      }

      @media screen and (max-width: 470px) {
        width: 100%;
      }
    }
  }
}
.about-div {
  display: flex;
  flex-direction: column;

  .about-child {
    flex: 1;
    padding: 20px;
    text-align: center;
    @media screen and (max-width: 1000px) {
      padding-bottom: 10px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .team-image-div {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;

    .team-image {
      width: auto;
      max-height: 300px;
      border-radius: 30% 70% 50% 30% / 30% 30% 80% 70% !important;

      @media screen and (max-width: 470px) {
        width: 100%;
      }
    }
  }
}

.team-members-list {
  padding: 0;
}

.values-main-div {
  padding-top: 40px;
}
