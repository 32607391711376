// @import url('https://fonts.googleapis.com/css?family=Inter:300,400,600');

/* Inter-300 */
@font-face {
  font-family: "Inter";
  font-display: swap;
  font-style: "light";
  font-weight: 300;
  src: local("Inter Light"), local("Inter-Light"),
    url("/assets/fonts/Inter-Light.ttf");
}

/* Inter-400 */
@font-face {
  font-family: "Inter";
  font-display: swap;
  font-style: "normal";
  font-weight: 400;
  src: local("Inter Regular"), local("Inter-Regular"),
    url("/assets/fonts/Inter-Regular.ttf");
}

/* Inter-600 */
@font-face {
  font-family: "Inter";
  font-display: swap;
  font-style: "bold";
  font-weight: 600;
  src: local("Inter SemiBold"), local("Inter-SemiBold"),
    url("/assets/fonts/Inter-SemiBold.ttf");
}

$modularscale: (
  base: 1em,
  ratio: $major-third,
);

$font--heading: "Inter", sans-serif;

body {
  font-family: $font--heading;
  font-size: 100%;
  line-height: 1.6;

  @media (min-width: $l) {
    font-size: 100%;
  }
}

h1,
h2,
h3,
.strapline,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  line-height: 1.1;
}

h1 {
  font-size: ms(3);
  font-weight: 300;
  margin-top: 1.5em;

  @media (min-width: $l) {
    font-size: ms(4);
  }
}

h2 {
  font-size: ms(2);
  font-weight: 300;

  @media (min-width: $l) {
    font-size: ms(3);
  }
}

h3 {
  font-size: ms(1);
  font-weight: 600;

  @media (min-width: $l) {
    font-size: ms(2);
  }
}

.strapline {
  display: block;
  font-size: ms(-1);
  margin-top: 0.5em;
}

h4 {
  font-size: ms(0);

  @media (min-width: $l) {
    font-size: ms(1);
  }
}

h5 {
  font-size: ms(0);

  @media (min-width: $l) {
    font-size: ms(1);
  }
}

h6 {
  font-size: ms(0);
}

p {
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 0;
}

li {
  &::marker {
    color: $colour--primary;
    font-weight: 600;
  }
}

blockquote {
  margin-top: 1em;
  margin-bottom: 0;
  position: relative;
  margin-right: 0;

  &::before {
    content: "\201C";
    color: $colour--primary;
    display: inline-block;
    font-size: ms(6);
    position: absolute;
    line-height: 1;
    top: -0.07em;
    font-family: "Inter", sans-serif;
  }

  p {
    text-indent: 1.75em;
    font-style: italic;
  }
}

cite {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0;
  position: relative;

  // &::before {
  //   content: '\2014\0020';
  // }

  p & {
    display: inline;
    margin-top: 0;
    position: static;
    font-style: normal;

    @media (min-width: $l) {
      font-size: ms(0);
    }
  }

  a {
    font-weight: 400;
  }
}

sup {
  font-size: 0.5em;
}

.caption {
  text-align: center;
}

._error-inner {
  color: #aa214f;
}
