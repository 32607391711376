.mc-banner {
  @media screen and (max-width: 900px) {
    margin-top: 141px;
  }

  @media screen and (max-width: 695px) {
    margin-top: 0;
  }

  @media screen and (max-width: 600px) {
    background-image: none;
    padding: 0;
    margin-bottom: 20px;
    min-height: auto;
  }

  position: relative; /* Ensure the pseudo-element is positioned relative to the banner */
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-position: left top;
  margin: 0;
  height: auto;
  overflow: hidden;
  padding: 20px 40px;
  color: rgb(242, 242, 242); /* Ensure the text color is still visible */
  background-image: url("/assets/img/masterclass-2024/masterclass.png");
  min-height: 50vh;
  margin-top: 90px;
}

.mc-banner-text {
  @media screen and (max-width: 600px) {
    background-image: none;
    background-color: #3b3840;
    color: rgba(245, 245, 245);
    margin: 0;
    padding: 30px;
    border-radius: 0;
    text-align: center;
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    margin: 0;
  }
  width: 66%;
  z-index: 2;
  display: flex;
  align-items: start;
  justify-content: start;
  margin: auto 0;
  color: #3b3840;
  background: rgba(245, 245, 245, 0.8);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 80px;

  h1,
  h2,
  h3 {
    font-weight: 400;
    margin: 0;
    padding: 10px;
  }
}

.mc-reg-text {
  font-size: 22px;
  font-weight: 400;
  color: #3b3840;

  @media screen and (max-width: 1020px) {
    font-size: 18px;
  }
}

.mc-about-text {
  font-size: 26px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
}

.mc-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 0;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
}

.mc-feature-cols {
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  display: flex;
  flex-direction: row;
  align-items: start;
  padding-top: 20px;
  padding-bottom: 20px;

  .mc-stack {
    font-size: 1.5em;
    padding-bottom: 20px;
  }
}

.mc-feature {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  font-size: 22px;
  font-weight: 400;
  color: #3b3840;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;

  @media screen and (max-width: 1020px) {
    font-size: 18px;
  }

  span {
    padding-top: 5px;
    padding-right: 5px;
    svg {
      align-items: flex-start;
      margin: 0 auto;
      min-width: 1em;
      min-height: 1em;
      height: 1em;
      width: 1em;
    }
  }
}

.mc-speaker-cols {
  display: flex;
  flex-direction: row;
  font-size: 20px;

  @media screen and (max-width: 1020px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1050px) {
    flex-direction: column-reverse;
  }
}

.mc-headshot {
  .headshot-img {
    @media only screen and (max-width: 500px) {
      width: 100%;
    }

    width: 350px;
    height: auto;
    border-radius: 5px;
  }

  @media only screen and (max-width: 1050px) {
    display: flex;
    justify-content: center;
  }
}

.mc-customer-parent-div {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 5px rgba(31, 33, 41, 0.6);

  .mc-customer-child-above {
    @media screen and (max-width: 800px) {
      padding: 50%;
    }

    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 40% 0 0 0;
  }

  .mc-customer-child-below {
    @media screen and (max-width: 1200px) {
      padding: 50%;
    }

    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 40% 0 0 0;
  }
}

.mc-customer-parent-div.child-below {
  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
}

.mc-customer-parent-div.child-above {
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.mc-customer-quote {
  font-size: 16px;
}

.mc-flex-parent {
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.mc-cta-button {
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: #40afe8;
  border: 2px solid #40afe8;
  width: auto;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  white-space: nowrap;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;

  @media screen and (max-width: 695px) {
    font-size: 16px;
  }
}

.mc-cta-button:hover {
  color: #40afe8;
  background-color: #fff;
}

.mc-strap {
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 695px) {
    position: relative;
    font-size: 24px;

    .mc-strap-div {
      padding-bottom: 10px;
    }
  }
  position: fixed;
  z-index: 3;
  width: 100%;
  background-color: #4b174d;
  color: white;
  font-size: 32px;
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
