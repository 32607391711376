.intro-small {
  text-align: left;

  @media (min-width: $l) {
    text-align: center;
  }
}

#error-container {
  display: flex;
  flex-direction: column;
  margin-top: $section-spacing/2;
  width: 100%;

  @media (min-width: $s) {
    flex-direction: row;
    justify-content: space-between;
  }

  .error-link {
    text-align: center;
    margin-bottom: 1rem;

    @media (min-width: $s) {
      margin-bottom: 0;
    }

    a {
      width: 100%;
    }
  }
}
