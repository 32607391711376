.post-details {
  // clear: both;
  // margin-top: $section-spacing/2;
  //  margin-left: 50px;
  //  border-top: 1px solid $colour--grey;
  color: $colour--grey--darker;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: ms(-1);
  margin-bottom: $section-spacing/2;

  @media (max-width: 560px) {
    align-items: baseline;
    flex-direction: column;
  }

  .avatar {
    // margin-top: $section-spacing/4;
    margin-right: 15px;
    height: 50px;
    width: 50px;
  }
  .author {
    margin-top: 0;
  }

  p + p {
    margin: 0;
  }
}

// #cards-div {
//   #cards {
//     list-style: none;
//     padding-left: 0;
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: repeat(4), 87vh;
//     gap: 4vw;
//     margin-bottom: 4vw;
//   }

//   #card1 {
//     z-index: 1;
//     position: absolute;
//     top: 200px;
//   }
//   #card2 {
//     z-index: 2;
//     position: sticky;
//     top: 0;
//     padding-top: 270px;
//   }
//   #card3 {
//     z-index: 3;
//     position: sticky;
//     top: 0;
//     padding-top: 290px;
//   }

//   #card4 {
//     z-index: 3;
//     position: sticky;
//     top: 0;
//     padding-top: 290px;
//   }

//   #card1 .card-body {
//     background-color: #52b2cf;
//   }
//   #card2 .card-body {
//     /* background-color: #7EC4CF; */
//     background-color: #e5a36f;
//   }
//   #card3 .card-body {
//     background-color: #9cadce;
//   }

//   #card4 .card-body {
//     background-color: transparent;
//   }

//   .card-body {
//     box-sizing: border-box;
//     padding: 30px;
//     border-radius: 50px;
//     box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
//     height: 87vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     transition: all 0.5s;
//   }

//   h2 {
//     font-size: 2.5em;
//   }
// }
