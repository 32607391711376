.hero-image {
  margin-bottom: 1em;
  margin-top: 1.5em;
  display: block;

  @media (min-width: $l) {
    @include layout($wide);
    @include pre(2 of 12);
    width: span(8 of 12);
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
