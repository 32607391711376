.profiles {
  padding-bottom: $section-spacing/2;

  @media (min-width: $m) {
    margin-top: $section-spacing;
    padding-top: $section-spacing;
    background-color: $colour--grey--lightest;

    .call-to-action + & {
      margin-top: 0;

      h2 {
        text-align: center;
        margin-bottom: 0;
      }
    }

    h2 {
      margin-top: 0;
    }
  }

  ul {
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $m) {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: $section-spacing/2;
    }
  }
}

.profile {
  @extend %clearfix;
  background-color: white;
  margin-top: 1em;

  @media (min-width: $m) {
    @include layout($medium);
    @include span(3 of 6);
    margin-top: $section-spacing/2;
    padding: 1em;

    &:nth-of-type(2n) {
      @include span(last 3 of 6);
    }

    &:nth-of-type(2n + 3) {
      clear: both;
    }
  }

  @media (min-width: $l) {
    @include layout($wide);
    @include span(4 of 12);

    &:nth-of-type(2n) {
      @include span(4 of 12);
    }

    &:nth-of-type(3n) {
      @include span(last 4 of 12);
    }

    &:nth-of-type(2n + 3) {
      clear: none;
    }

    &:nth-of-type(3n + 4) {
      clear: both;
    }
  }
}
