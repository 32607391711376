.privacy-cols {
    margin-top: 1em;
}

.privacy-row {
    border-top: 1px solid black;
    display: flex;
}

.privacy-row:last-of-type {
    border-bottom: 1px solid black;
}

.col-left {
    border-left: 1px solid black;
    padding: 0.5em;
    width: 30%;

    &.header {
        font-weight: 600;
        text-align: center;
    }
}

.col-right {
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 0.5em;
    width: 70%;

    &.header {
        font-weight: 600;
        text-align: center;
    }

    p {
        margin-top: 0;
    }
}
