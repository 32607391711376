.footer {
  // @extend %gradient--horizontal;
  background-color: $colour--grey--darkestest;
  color: white;
  padding-top: $section-spacing/2;
  padding-bottom: $section-spacing/2;
  //   margin-top: $section-spacing;

  h2 {
    font-size: ms(1);
  }

  > .container {
    @extend %clearfix;
  }

  ul {
    @extend %list-style-remove;
  }

  a {
    font-size: 80%;
    text-decoration: none;
    color: white;

    &:hover,
    &:active,
    &:focus {
      color: white;
      text-decoration: underline;
    }
  }

  .company-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    flex-direction: row;

    @media screen and (max-width: 400px) {
      justify-content: start;
      flex-direction: column;
      align-items: start;
    }

    > div {
      width: 50%;
    }

    .social-media {
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 400px) {
        padding: 10px 0;
        width: 100%;
        justify-content: start;
      }

      .social-media--item {
        padding: 0 0.5rem;

        @media (min-width: $s) {
          padding: 0 1rem;
        }

        a {
          img {
            display: inline-block;
            transition: 0.3s;
            height: 28px;
            width: auto;

            &:hover {
              filter: drop-shadow(0px 0px 6px #c03c67);
            }
          }
        }
      }
    }
  }

  .quick-links {
    margin-bottom: 1em;

    ul {
      margin-top: 0;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;

      @media (min-width: $s) {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
      }

      @media (min-width: $l) {
        columns: 4;
        -webkit-columns: 4;
        -moz-columns: 4;
      }

      //   @media (min-width: $xl) {
      //     columns: 5;
      //     -webkit-columns: 5;
      //     -moz-columns: 5;
      //   }

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}
