.image-wrap {
  float: left;
  max-width: 700px;
  width: 100%;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 25px;

  img {
    width: 100%;
    height: auto;
    max-width: 700px;
  }
}

.action-image-wrap {
  float: right;
  max-width: 700px;
  width: 100%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 25px;
}

.text-wrap {
  padding-bottom: 20px;
}

.text-wrap p {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
}

.text-wrap > h2 {
  padding-top: 20px;
  font-weight: 600;
  font-size: 28px;
}

.text-wrap > h3 {
  font-weight: 600;
  font-size: 22px;
  font-style: italic;
}

.text-wrap > h5 {
  font-weight: 600;
  font-size: 18px;
}

.text-wrap > ul {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  li {
    padding-bottom: 5px;
  }
}

.media-link {
  color: #4b174d;
}

.media-link:hover {
  text-decoration: none;
  color: #4b174dc2;
}

/* Base styles for the image container */
.pod-img-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Display all 5 images in one row */
  gap: 30px; /* Gap between images */
}

/* Make sure images don't overflow and maintain their aspect ratio */
.pod-img-container img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  transition: transform ease 0.3s;
}

.pod-img-container img:hover {
  transform: scale(1.05);
}

.bg-gradient-media {
  background: linear-gradient(120deg, #fdfcfc 0%, #ebedee 100%);
}

/* Media Query for when the screen width is below 1000px */
@media (max-width: 1000px) {
  .pod-img-container {
    grid-template-columns: repeat(3, 1fr); /* 3 images in the first row */
  }
}

/* Media Query for mobile view (below 600px) */
@media (max-width: 600px) {
  .pod-img-container {
    grid-template-columns: 1fr; /* One image per row */
  }
}

@media (max-width: 1200px) {
  .image-wrap,
  .action-image-wrap {
    float: none;
    margin: 0 auto 20px auto;
    display: block;
    text-align: center;
  }
}
