%button--feature {
  background: $colour--primary--dark;
  // background-image: linear-gradient(to right,  $colour--quaternary--lighter 0%, $colour--quaternary 100%);
  color: $colour--white;
  font-family: $font--heading;
  font-weight: 400;
  transition: background-image 500ms linear;
  position: relative;
  border-radius: $border-radius;
  z-index: 1;
  border: 0;

  // &::before {
  //   // background-image: linear-gradient(to right,  $colour--quaternary 0%, $colour--quaternary--lighter 100%);
  //   background: colour--primary;
  //   z-index: -1;
  //   position: absolute;
  //   content: "";
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   transition: opacity 0.5s linear;
  //   opacity: 0;
  //   border-radius: $border-radius;
  // }

  // &:hover::before {
  //   // background: colour--primary;
  //   opacity: 1;
  // }
}

.button--blog-tag {
  @extend %button--inverted;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 12px;
  margin-bottom: 6px;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.button-list {
  .button {
    margin-right: $spacer;
  }
}

.atcb-button {
  z-index: 0 !important;
}
