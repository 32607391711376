a {
  //   color: $colour--primary;
  text-decoration: underline;

  //   &:visited {
  //     color: $colour--primary;
  //   }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  // &:focus {
  //   background-color: transparentize($colour--secondary, .7);
  // }
}
