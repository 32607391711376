.vision {
  @extend %panel--light;

  > .container {
    @extend %clearfix;
  }

  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(6 of 12);
    }
  }

  .illustration {
    @include layout($narrow);
    @include span(2 of 4);
    // margin-top: 1em;
    float: unset;
    margin: 1em auto 0 auto;

    @media (min-width: $m) {
      @include layout($medium);
      @include pre(0 of 6);
      @include span(2 of 6);
      float: unset;
      margin: 1em auto 0 auto;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(3 of 12);
      float: unset;
      margin: 1em auto 0 auto;
    }
  }
}

.core-objectives {
  @extend %panel;

  > .container {
    @extend %clearfix;
  }

  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(last 6 of 12);
    }
  }

  .illustration {
    @include layout($narrow);
    @include span(3 of 4);
    float: unset;
    margin: 1em auto 0 auto;

    @media (min-width: $s) {
      @include layout($narrow);
      @include span(2 of 4);
      float: unset;
      margin: 1em auto 0 auto;
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include pre(1 of 6);
      @include span(2 of 6);
      float: unset;
      margin: 1em auto 0 auto;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include pre(2 of 12);
      @include span(4 of 12);
      float: unset;
      margin: 1em auto 0 auto;
    }
  }
}

.feature {
  @extend %panel--light;
}

.key-results-image {
  display: none;

  @media screen and (min-width: 688px) {
    display: block;
  }
}

.prompts,
.effectiveness-tracking {
  @extend %clearfix;

  .illustration {
    display: block;
    margin-top: 1em;
  }
}

.prompts {
  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(4 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(6 of 12);
    }
  }

  .illustration {
    @include layout($narrow);
    max-width: span(2 of 4);
    float: unset;
    margin: 1em auto 0 auto;

    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 2 of 6);
      float: unset;
      margin: 1em auto 0 auto;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(2 of 12);
      float: unset;
      margin: 1em auto 0 auto;
    }
  }
}

.effectiveness-tracking {
  margin-top: $section-spacing/2;
  margin-bottom: $section-spacing;

  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(last 6 of 12);
    }
  }

  .illustration {
    @include layout($narrow);
    max-width: span(3 of 4);

    @media (min-width: $m) {
      @include layout($medium);
      @include span(3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include pre(2 of 12);
      @include span(4 of 12);
    }
  }
}
