form {
  @extend %container;
  max-width: 100%;
  margin-top: $section-spacing/2;

  @media (min-width: $m) {
    @include layout($medium);
    @include pre(1 of 6);
    max-width: span(4 of 6);
  }

  @media (min-width: $l) {
    @include layout($wide);
    @include pre(2 of 12);
    max-width: span(6 of 12);
  }
}

[type="text"],
[type="email"],
[type="file"],
[type="password"],
[type="number"],
textarea {
  background-color: $colour--white;
  border: 1px solid $colour--primary;
  border-radius: 2px;
  display: block;
  padding: 0.5em 1em;
  width: 100%;

  &:focus {
    border-color: $colour--black;
  }
}

[type="text"],
[type="email"],
[type="file"],
[type="password"],
[type="number"],
textarea {
  width: 100%;
}

.form-group {
  + .form-group,
  + p,
  p + & {
    margin-top: 1em;
  }

  + fieldset,
  fieldset + & {
    margin-top: 2em;
  }
}

legend {
  font-size: ms(1);
}

input {
  line-height: 1.2;
}

textarea {
  height: 10em;
}

[type="submit"] {
  @extend %button;
}

$indicator-size: 28px;

.radio,
.checkbox {
  position: relative;
  display: block;
  padding-left: $indicator-size * 1.25;
  cursor: pointer;
  line-height: $indicator-size;
  margin-top: 0.5em;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
  }

  .inline & {
    display: inline-block;
    margin-top: 0;

    + .checkbox {
      margin-left: 1em;
    }
  }

  .control-indicator {
    position: absolute;
    top: -0.1em;
    left: 0;
    display: block;
    width: $indicator-size;
    height: $indicator-size;
    line-height: $indicator-size;
    text-align: center;
    border: 3px solid $colour--primary;
    user-select: none;
    background-color: $colour--white;
    margin-right: 0.5em;
  }

  input:focus ~ .control-indicator {
    border-color: $colour--black;
  }

  input:checked ~ .control-selected {
    position: absolute;
    display: block;
    text-align: center;
    user-select: none;
  }

  input:checked ~ .control-selected {
    top: $indicator-size/12;
    left: $indicator-size/3;

    &::after {
      content: "";
      display: block;
      width: $indicator-size/3;
      height: $indicator-size/1.7;
      border: solid $colour--primary;
      border-width: 0 4px 4px 0;
      transform: rotate(45deg);
    }
  }
}

.checkbox {
  input:checked ~ .control-selected {
    top: $indicator-size/12;
    left: $indicator-size/3;

    &::after {
      width: $indicator-size/3;
      height: $indicator-size/1.7;
      border: solid $colour--primary;
      border-width: 0 4px 4px 0;
      transform: rotate(45deg);
    }
  }

  input:focus ~ .control-selected::after {
    border-color: $colour--black;
  }
}

.radio {
  .control-indicator {
    border-radius: 1em;
  }

  input:checked ~ .control-selected {
    top: 6px;
    left: 8px;

    &::after {
      border-radius: 50%;
      border: none;
      width: 12px;
      height: 12px;
      background-color: $colour--primary;
    }
  }

  input:focus ~ .control-selected::after {
    background-color: $colour--black;
  }
}
