img {
  p + & {
    margin-top: 1em;
  }

  a:focus & {
    box-shadow: 0 0 0 3px $colour--secondary;
  }
}

.home-banner-div {
  display: none;
  padding-top: 10px;

  .business-banner {
    height: 85px;
    width: auto;
    border-radius: 5px;
    filter: drop-shadow(0px 0.25em 0.25em rgba(0, 0, 0, 0.25));
  }

  @media only screen and (max-width: 450px) {
    .business-banner {
      width: 100%;
      height: auto;
    }
  }
}

.banner-div {
  display: flex;
  padding: 10px;

  .business-banner {
    height: 85px;
    width: auto;
    border-radius: 5px;
    filter: drop-shadow(0px 0.25em 0.25em rgba(0, 0, 0, 0.25));
  }

  @media only screen and (max-width: 450px) {
    .business-banner {
      width: 100%;
      height: auto;
    }
  }
}
