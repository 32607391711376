.productivity-puzzle {
  @extend %clearfix;
  font-size: ms(1);
  margin-top: 2em;

  p {
    margin-top: 0;
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 1.5em;
    }
  }

  blockquote {
    margin-bottom: 1em;

    p {
      color: $colour--primary--dark;
      margin-bottom: 1em;

      &:last-of-type {
        margin-bottom: 0em;
      }
    }

    cite {
      margin-top: 0;
    }
  }

  * {
    transition: all 0.2s ease;
  }

  header {
    margin-bottom: 1.5em;
  }

  h1 {
    text-align: left;
    margin-top: 0;
  }
  h2 {
    text-align: center;
    margin-top: 0;
  }

  ul {
    font-weight: 300;
    list-style-image: url("/assets/img/check.svg");
    margin-top: 1em;
    line-height: 1.3;
  }

  ul.standard {
    list-style-image: unset;

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .text {
    @extend %clearfix;
    @include layout($narrow);
    @include span(4 of 4);

    // @media (min-width: $m) {
    //   @include layout($medium);
    //   @include span(last 3 of 6);
    // }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(12 of 12);
    }

    img {
      margin: 0 auto;
      max-width: 600px;
      width: 100%;
    }

    // background-color: $colour--grey--lighter;
    // padding: 3rem;
    // color: $colour--grey--darkestest;
    // border-radius: 5px;

    form {
      position: relative;
      text-align: left;
      margin: 0;
      padding: 0px;
      max-width: 100%;

      fieldset {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        padding: 20px !important;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;

          @media (min-width: $m) {
            flex-direction: row;
            justify-content: space-between;
          }

          .intro-section--text {
            flex-basis: 60%;
            @media (min-width: $s) {
              padding-right: 1em;
            }
          }

          .form-section {
            flex-basis: 40%;
            @media (min-width: $s) {
              padding-left: 1em;
            }
          }
        }
      }

      ._form_element {
        margin: 0 auto 1em auto;
        max-width: 600px;
      }

      ._submit {
        background-color: #40afe8;
        border-radius: 5px;
        color: white;
        height: 50px;
        font-weight: 200;
        font-size: 1em;
        &:hover {
          background-color: $colour--primary--darker;
        }
      }

      input {
        border: 2px solid $colour--quaternary;
      }

      .form-buttons-wrapper {
        margin-top: 2em;
        text-align: center;
      }

      .share-button-container {
        text-align: center;
      }

      button,
      input[type="button"] {
        @extend %button--inverted;

        font-size: ms(0);
        margin: 0 auto;
        cursor: pointer;
      }

      a {
        color: $colour--primary;
      }

      /* For the function */
      .msf_hide {
        display: none;
      }

      .msf_show {
        display: block;
      }

      .msf_bullet_o {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
      }

      .msf_bullet_o > div {
        height: 15px;
        width: 15px;
        margin: 20px 10px;
        border-radius: 100px;
        z-index: 2;
      }

      .msf_bullet {
        background-color: lightgrey;
      }

      .msf_bullet_active {
        background-color: darkgrey !important;
      }

      .msf_line {
        opacity: 0.3;
        background: lightgrey;
        height: 3px;
        width: 70px;
        display: block;
        left: 50%;
        margin-top: -29px;
        margin-left: -35px;
        position: absolute;
        z-index: 1;
      }
    }
  }

  .mobile.mobile {
    // Chained for specificity
    box-shadow: none;
  }

  .mobile {
    filter: drop-shadow(0px 0px 1em rgba(0, 0, 0, 0.1));
  }
}

.privacy-statement {
  margin-top: 0.5em;
}

#thank-you-container {
  display: flex;
  flex-direction: column;
  margin-top: $section-spacing/2;
  width: 100%;

  @media (min-width: $s) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .thank-you-link {
    text-align: center;
    margin-bottom: 1rem;

    @media (min-width: $s) {
      margin-bottom: 0;
    }

    a {
      width: 100%;
      color: white;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   -webkit-transition: .4s;
//   transition: .4s;
// }

// .slider:before {
//   position: absolute;
//   content: "";
//   height: 26px;
//   width: 26px;
//   left: 4px;
//   bottom: 4px;
//   background-color: white;
//   -webkit-transition: .4s;
//   transition: .4s;
// }

// input:checked + .slider {
//   background-color: #2196F3;
// }

// input:focus + .slider {
//   box-shadow: 0 0 1px #2196F3;
// }

// input:checked + .slider:before {
//   -webkit-transform: translateX(26px);
//   -ms-transform: translateX(26px);
//   transform: translateX(26px);
// }

// /* Rounded sliders */
// .slider.round {
//   border-radius: 34px;
// }

// .slider.round:before {
//   border-radius: 50%;
// }
