.floating-banner {
    box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 90px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 90px;
    display: block;

    &.hide {
        display: none;
    }

    &.user-hidden {
        display: none;
    }

    padding: 10px 0px;
    background: $colour--grey--lightest;
    color: $colour--grey--darkestest;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid black;

    #close-button {
        border-radius: 50%;
        padding: 0.5em;
        width: 30px;
        height: 30px;
        background-color: $white;
        border: 2px solid $colour--primary--dark;
        color: $colour--primary--dark;
        position: absolute;
        right: 0px;
        top: -15px;
        cursor: pointer;
    }

    #close-button:hover {
        border: 2px solid $colour--primary--dark;
        background-color: $white;
        color: $white;
    }

    #close-button::before {
        content: " ";
        position: absolute;
        display: block;
        background-color: $colour--primary--dark;
        width: 2px;
        left: 12px;
        top: 5px;
        bottom: 5px;
        transform: rotate(45deg);
    }

    #close-button::after {
        content: " ";
        position: absolute;
        display: block;
        background-color: $colour--primary--dark;
        height: 2px;
        top: 12px;
        left: 5px;
        right: 5px;
        transform: rotate(45deg);
    }

    .float-container {
        padding: 5px;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        &.hide {
            display: none;
        }

        @media (min-width:1150px) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: left;
        }

        // @media only screen and (min-width : 1600px) {
        //     max-width: 80%;
        // }

        >div {
            margin-bottom: 0.5rem;
            @media (min-width:1150px) {
                margin-bottom: 0;
            }
        }

        .floating-banner--text {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h2 {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
        }

        .floating-banner--input {
            display: flex;
            flex-direction: column;

            input {
                width: 300px;
                max-width: 380px;
                margin: 0 auto;

                &:nth-of-type(2) {
                    margin-top: 0.5rem;
                    @media (min-width:1150px) {
                        margin: 0 auto 0 0.5rem;
                    }
                }


            }

            @media (min-width:1150px) {
                flex-direction: row;
                margin-right: 0.5rem;
                margin-left: 3rem;
            }
        }

        .floating-banner--submit {
            margin-bottom: 0;

            button {
                @extend %button--inverted;
                font-size: 16px;
                padding: 0.55em 1em;
            }
        }

        .form {
            form {
                width: 100%;
                max-width: 100%;
                margin: 0;

                ._form-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (min-width:1150px) {
                        flex-direction: row;
                        justify-content: end;
                        align-items: start;
                    }

                    ._form_element {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @media (min-width:1150px) {
                            align-items: start;
                        }

                        ._field-wrapper {
                            background-color: #f9f9f9;
                            border: 1px solid #d3d3d3;
                            border-right: none;
                            border-radius: 3px 0 0 3px;
                            box-shadow: 0 0 4px 1px rgb(0 0 0 / 8%);
                            -webkit-box-shadow: 0 0 4px 1px rgb(0 0 0 / 8%);
                            width: 300px;
                            height: 76px;
                        }

                        input {
                            border: 2px solid #c1c1c1;
                            border-radius: 3px;
                            margin: auto 12px;
                            padding: 0.3em 0.5em;
                        }
                    }

                    ._button-wrapper {
                        @media (min-width:1150px) {
                            align-self: end;
                        }

                        button {
                            @extend %button--inverted;
                            font-size: ms(0);
                            margin: 10px 0 4px 2rem;
                            padding: 0.5em 2em;

                            @media (min-width:1150px) {
                                margin: 0 0 4px 2rem;
                            }
                        }

                        .privacy-statement {
                            margin-left: 2rem;
                        }
                    }
                }
            }
        }
    }
}
