.blog-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: $border-radius;

  > a,
  img {
    display: block;
  }

  img {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .text {
    flex-grow: 1;
    padding: 0.5em 1em 1.5em;
  }

  h2,
  h3 {
    margin-top: 0;
    font-size: ms(1);
    font-weight: 400;
  }

  a {
    color: $colour--black;
    text-decoration: underline;

    &:visited {
      color: $colour--black;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  .blog-list-item--categories {
    padding: 0 0.5em 0.5em 1em;

    .blog-list-item--category {
      display: inline-block;
      padding: 0.25em;
      background-color: #e2e2e2;
      border-radius: 0.25em;
      line-height: 1;
      font-size: 0.9em;
    }
  }
}

.tool-list-item {
  display: flex;
  border: 2px solid #f2f2f2;
  border-radius: $border-radius;
  margin-top: 20px;
  margin-bottom: 20px;

  > a,
  img {
    display: block;
  }

  img {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .text {
    flex-grow: 1;
    padding: 0.5em 1em 1.5em;
  }

  h2,
  h3 {
    margin-top: 0;
    font-size: ms(1);
    font-weight: 400;
  }

  a {
    color: $colour--black;
    text-decoration: underline;

    &:visited {
      color: $colour--black;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}
