a.google-reviews-stars {
  align-items: center;
  border: 2px solid #40afe8;
  border-radius: 5px;
  color: #3b3840;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  //   margin-bottom: 1.5em !important;
  //   margin-left: 4rem;
  padding: 0.75rem 1rem 0.35rem 1rem;
  position: relative;
  text-decoration: none;
  transition: all 0.2s linear;

  &:hover {
    border: 2px solid #4b174d;
  }

  &:visited {
    color: unset;
  }

  .reviews-meta {
    width: 136px;

    span {
      color: #4b174d;
      font-size: 14px;
      line-height: 1;
      text-align: center;
    }
  }
}

.google-stars {
  background: #ffffff;
  border: 0;
  border-radius: 0;
  font: inherit;
  line-height: 1;
  list-style: none;
  margin: 0;
  outline: 0;
  overflow: visible;
  padding: 0 4px;
  position: absolute;
  top: -10px;
  vertical-align: baseline;

  .google-star {
    vertical-align: baseline;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0px;
    background-size: 18px 18px;
    background-repeat: no-repeat;

    &.full {
      background-image: url(//maps.gstatic.com/consumer/images/icons/2x/ic_star_rate_14.png);
    }

    &.half {
      background-image: url(//maps.gstatic.com/consumer/images/icons/2x/ic_star_rate_half_14.png);
    }
  }
}
