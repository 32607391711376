$midnight: #2c3e50;
$clouds: #ecf0f1;

.faqs {
  padding-top: $section-spacing;

  h2 {
    margin-top: 0;

    @media (min-width: $s) {
      text-align: center;
    }
  }

  dl {
    @media (min-width: $s) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.faq-item {
  margin-bottom: $section-spacing/3;

  @media (min-width: $s) {
    @include layout($medium);
    @include span(3 of 6);

    &:nth-of-type(2n) {
      @include layout($medium);
      @include span(last 3 of 6);
    }

    &:nth-of-type(3n) {
      clear: both;
    }
  }

  @media (min-width: $l) {
    @include layout($wide);
    @include span(4 of 12);

    &:nth-of-type(2n) {
      @include layout($wide);
      @include span(4 of 12);
    }

    &:nth-of-type(3n) {
      @include layout($wide);
      @include span(last 4 of 12);
      clear: none;
    }

    &:nth-of-type(4n) {
      clear: both;
    }
  }
}

.faq-question {
  margin: 0 0 0.5em;
  font-weight: normal;
}
.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.col {
  flex: 1;
  &:last-child {
    margin-left: 1em;
  }
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 100%;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em 0 1em 0;
    cursor: pointer;
    font-size: 1.5em;
    border-bottom: 1px solid #ecf0f1;

    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    transition: all 0.35s;
    font-weight: 300;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
}

.tab-label {
  color: #4b174d;
}

// :checked
.tab input:checked {
  + .tab-label {
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}
