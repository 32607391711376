.social-share-floating-bar {
  @media (max-width:1220px) {
    display:none
  }

  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
}

.social-share-blog {
  @media (min-width:1221px) {
    display: none
  }
  align-self: center;
  margin-left: 1rem;

  @media (max-width:560px) {
    align-self: flex-start;
    margin-left: 0;
    margin-top: 1rem;
  }
}

.social-share-floating-bar a, .social-share-blog a {
  transition: all 0.3s ease;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.social-share-floating-bar a {
  display: block;
  padding: 16px;
}

.social-share-blog a {
  display: inline-block;
  padding: 10px;
}

.social-share-btns-container {
  font-size: 14px;
  text-align: center;
}

.social-share-btns {
  display: inline-block;
}

.social-share-btns .share-btn {
  margin: 0 5px;
  padding: 4px 8px;
  color: #fff;
}

.social-share-btns .share-btn i {
  display: inline-block;
  margin-right: 5px;
}

.share-btn {
  background-color: #95a5a6;
  
}

.share-btn:hover, .share-btn:active, .share-btn:visited {
  background-color: #798d8f;
  text-decoration: none;
}

.share-btn-twitter {
  background-color: #00aced;
}

.share-btn-twitter:hover, .share-btn-twitter:active, .share-btn-twitter:visited {
  background-color: #0087ba;
}

.share-btn-facebook {
  background-color: #3b5998;
}

.share-btn-facebook:hover, .share-btn-facebook:active, .share-btn-facebook:visited {
  background-color: #2d4373;
}

.share-btn-linkedin {
  background-color: #007bb6;
}

.share-btn-linkedin:hover, .share-btn-linkedin:active, .share-btn-linkedin:visited {
  background-color: #005983;
}

.social-share-container {
  @media (max-width:1220px) {
    display: flex;
    // flex-direction: row;
  }

  // @media (max-width:560px) {
  //   flex-direction: column;
  // }

  img {
    width: 20px;
    height: 20px;
  }
}
