html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

pre,
code {
  font-size: 100%;
  font-weight: 400;
}

pre,
body,
html,
fieldset,
input {
  margin: 0;
  padding: 0;
}

address,
cite {
  font-style: normal;
}

a:focus {
  outline: none;
}

a img,
img,
iframe,
fieldset {
  border: 0;
}

legend {
  padding: 0;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 1px;
}

select {
  padding: 0;
}

table {
  border-collapse: collapse;
}

:focus {
  outline: none;
}

[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer;
}

[type="search"] {
  -webkit-appearance: none;
  box-shadow: none;
}

[type="text"],
[type="email"],
[type="tel"],
[type="url"],
textarea {
  -webkit-appearance: none;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button {
  display: none;
}

button {
  background-image: none;
}

nav .selected {
  cursor: default;
}

textarea {
  overflow-y: auto;
  resize: vertical;
}

img,
svg {
  display: block;
}

img {
  height: auto;
  width: 100%;
}

svg {
  height: auto;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

blockquote {
  margin-left: 0;
}
