.benefits {
  //   background-color: $colour--grey--lightest;

  .container {
    > ul {
      @extend %list-style-remove;
      @extend %clearfix;
    }
  }

  .benefit {
    margin-bottom: 1em;
    padding: 10px;
    position: relative;
    top: 0;
    transition: all ease 0.3s;
    // border-radius: 10px;
    // border: 2px solid #c8c8c8;
    cursor: default;
    // box-shadow: 5px 5px 8px #8a8a8a40;

    // .text {
    //   color: #3b3840;
    // }

    // @media (min-width: $s) {
    //   @include layout($medium);
    //   @include span(2 of 6);

    //   &:nth-of-type(2n) {
    //     @include layout($medium);
    //     @include span(last 2 of 6);
    //   }

    //   &:nth-of-type(3n), &:nth-of-type(5n) {
    //     clear: both;
    //   }
    // }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(4 of 12);

      &:nth-of-type(2n) {
        @include layout($wide);
        @include span(4 of 12);
      }

      &:nth-of-type(3n) {
        @include layout($wide);
        @include span(last 4 of 12);
      }

      &:nth-of-type(4n) {
        clear: both;
      }
    }
  }

  //   .benefit:hover {
  //     box-shadow: 0 0 11px rgba(52, 52, 52, 0.4);
  //     top: -10px;
  //   }
}
