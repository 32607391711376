#site-nav {
  position: fixed;
  background-color: white;
  width: 100%;
  top: 0;
  border-bottom: 0;
}

.nav-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.header-tag-line {
  .header-sub-head {
    font-size: 80%;
    margin-top: 0.5em;
  }
  @media screen and (max-width: 800px) {
    text-align: left;
  }

  @media screen and (max-width: 1400px) {
    font-size: 100%;
  }
  font-size: 110%;
  line-height: 1.3;
  color: #551a50;
}

.home-sub {
  @media screen and (max-width: 800px) {
    text-align: left;
  }
  text-align: left;
}

.home-checks {
  @media screen and (max-width: 800px) {
    flex-direction: column;
    font-size: 20px;
    align-items: start;
    text-align: start;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0.8em;
  font-size: 24px;
  gap: 20px;
  color: #3b3840;
}

.home-sub {
  @media screen and (max-width: 800px) {
    text-align: left;
  }

  @media screen and (max-width: 1400px) {
    font-size: 80%;
  }

  font-size: 100%;
  text-align: left;
}

.new-info-banner {
  background: linear-gradient(
    to right,
    rgba(192, 60, 103, 1) 0%,
    rgba(178, 55, 100, 1) 10%,
    rgba(153, 47, 94, 1) 15%,
    rgba(132, 41, 90, 1) 20%,
    rgba(107, 33, 84, 1) 25%,
    rgba(89, 27, 81, 1) 50%,
    rgba(75, 23, 77, 1) 100%
  );
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
    font-size: 0.7em;
  }

  .demo-text-hide-on-mobile {
    display: inline;
  }

  @media screen and (max-width: 700px) {
    .demo-text-hide-on-mobile {
      display: none;
    }
  }
}

/* Add animation on hover. */
.header-tag-line:hover {
  animation: rainbow-text-animation 0.5s ease forwards;
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-animation {
  0% {
    background-size: 57%;
    background-position: 0 0;
  }
  20% {
    background-size: 57%;
    background-position: 0 1em;
  }
  100% {
    background-size: 200%;
    background-position: -6em 1em;
  }
}

/* Move the background and make it smaller. */
/* Animation shown when entering the page and after the hover animation. */
@keyframes rainbow-text-animation-rev {
  0% {
    background-size: 300%;
    background-position: -9em 1em;
  }
  20% {
    background-size: 57%;
    background-position: 0 1em;
  }
  100% {
    background-size: 57%;
    background-position: 0 0;
  }
}

.partners-header {
  display: flex;
  flex: 1 1 0px;
  flex-direction: row-reverse;
  align-items: stretch;
  min-height: 250px;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }

  .partners-text {
    flex: 1 1 0px;
  }

  @media screen and (max-width: 800px) {
    .partners-text {
      flex: 0 0 100% !important;
      width: 100%;
    }
  }
}

.partner-header-image {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  border-top: 0px;
  box-shadow: 5px 5px 3px #aaaaaa;
  flex: 1 1 0px;
  object-fit: cover;
  height: 100%;
  float: right;

  @media screen and (max-width: 800px) {
    flex: 0 0 100% !important;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.already-partner-link {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #3b3840;
}

[role="banner"] {
  margin-top: 140px;

  @media only screen and (max-width: 1282px) and (min-width: 843px) {
    margin-top: 231px;
  }

  @media only screen and (max-width: 842px) and (min-width: 761px) {
    margin-top: 286px;
  }

  @extend %clearfix;
  background: $colour--white;
  color: $colour--black;
  margin-bottom: $section-spacing;

  @media (min-width: $l) {
    text-align: center;
  }

  .container :last-child {
    margin-bottom: 0;
  }

  .highlight {
    font-weight: 600;
    color: $colour--primary--dark;
    background: -webkit-linear-gradient(
      top,
      $colour--primary 0%,
      $colour--primary 40%,
      $colour--primary--dark 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home & {
    > .container {
      @extend %clearfix;
    }

    @media (min-width: $l) {
      text-align: left;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      //   @media (min-width: $m) {
      //     // font-size: ms(2);
      //     @include layout($medium);
      //     @include span(3 of 6);
      //   }

      //   @media (min-width: $l) {
      //     // font-size: ms(2);
      //     @include layout($wide);
      //     @include span(6 of 12);
      //   }

      h1 {
        font-size: 1.5em;
        line-height: 1.5;
        // margin-bottom: 1.5em;
        margin-top: 1.25em;

        text-align: center;

        @media (max-width: $s) {
          font-size: 1.5em;
        }

        @media (min-width: $s) {
          font-size: 1.75em;
        }

        @media (min-width: $m) {
          font-size: 2em;
        }

        @media (min-width: $l) {
          font-size: 2.25em;
        }
      }

      h2.subheading {
        font-size: 1.2em !important;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        text-align: center;

        @media (min-width: $s) {
          font-size: 1.4em;
        }
      }
    }

    .illustration {
      margin-top: 50px;
      margin-bottom: $section-spacing / 2;
      perspective: 280px;

      .home-ill-div {
        padding: 1rem;
        border: 1px solid rgb(17 24 39 / 0.1);
        border-radius: 1rem;
        background-color: rgb(17 24 39 / 0.05);
        overflow: hidden;

        .home-illustration {
          border: 1px solid rgb(17 24 39 / 0.1);
          border-radius: 0.375rem;
          box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
          height: 50%; /* Resetting height */
          width: 100%; /* Ensure the image takes the full width of its container */
          position: relative; /* Set position to relative */
          top: 0; /* Align the top of the image to the top of its container */
          left: 0; /* Align the left of the image to the left of its container */
          transform: translateY(0);
        }

        .home-illustration.mobile {
          display: none;
        }

        .home-illustration.web {
          display: block;
        }

        @media only screen and (max-width: 700px) {
          .home-illustration.mobile {
            display: block;
          }

          .home-illustration.web {
            display: none;
          }
        }
      }

      //   @media (min-width: $m) {
      //     margin-top: $section-spacing / 2;
      //     @include layout($medium);
      //     @include span(last 3 of 6);
      //     margin-top: 50px;
      //     margin-bottom: 50px;
      //   }

      //   @media (min-width: $l) {
      //     @include layout($wide);
      //     @include span(last 6 of 12);
      //   }

      .device {
        background-color: #f7f9fa;
        border: 16px solid #222222;
        border-radius: 1.3rem;
        box-shadow: 12px 8px 22px 5px #cecccc, 3px 0px 0px 0px #000000;
        -webkit-box-shadow: 12px 8px 22px 5px #cecccc, 4px 0px 0px 0px #000000;
        margin-right: 0.75rem;
        padding: 0.1rem 0.75rem 0.75rem 0.75rem;
        transform: rotateX(1deg) rotateY(354deg);
        -webkit-transform: rotateX(1deg) rotateY(354deg);
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;

        @media (min-width: $s) {
          transform: rotateX(1deg) rotateY(354deg) translateX(-36px);
          -webkit-transform: rotateX(1deg) rotateY(354deg) translateX(-36px);
        }

        @media (min-width: $m) {
          transform: rotateX(1deg) rotateY(354deg);
          -webkit-transform: rotateX(1deg) rotateY(354deg);
        }

        .toolbar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          font-size: 11px;
          color: #62717a;

          .icons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 35px;

            .gg-signal,
            .gg-signal::after,
            .gg-signal::before {
              display: block;
              box-sizing: border-box;
              width: 2px;
              border-radius: 4px;
              background: currentColor;
            }

            .gg-signal {
              position: relative;
              transform: scale(var(--ggs, 1));
              height: 7px;
            }

            .gg-signal::after,
            .gg-signal::before {
              content: "";
              position: absolute;
            }

            .gg-signal::before {
              height: 11px;
              left: 4px;
              bottom: 0;
            }

            .gg-signal::after {
              height: 4px;
              left: -4px;
              bottom: 0;
            }

            .gg-battery {
              box-sizing: border-box;
              position: relative;
              display: block;
              width: 20px;
              height: 10px;
              transform: scale(var(--ggs, 1));
              border: 2px solid;
              border-radius: 3px;
              margin-left: -3px;
            }

            .gg-battery::after,
            .gg-battery::before {
              content: "";
              display: block;
              box-sizing: border-box;
              position: absolute;
              height: 4px;
              background: currentColor;
              top: 1px;
            }

            .gg-battery::before {
              right: -4px;
              border-radius: 3px;
              width: 4px;
            }

            .gg-battery::after {
              width: 8px;
              left: 1px;
            }
          }
        }

        .row--overall-performance {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 0.5rem;

          .overall-performance {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background-color: #ffffff;
            border-radius: 0.5rem;
            padding-bottom: 0.5rem;
            text-align: center;
            border: 2px solid #40afe8;

            .gauge-container {
              width: 180px;
              height: 180px;
              display: block;
              border-radius: 3px;
              position: relative;
            }

            h4 {
              margin-top: 0;
            }
          }
        }

        .row--individual-performance {
          display: flex;
          flex-direction: row;
          justify-content: center;

          .individual-performance {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 33.33%;
            background-color: #ffffff;
            border-radius: 0.5rem;
            padding-bottom: 0.5rem;
            text-align: center;
            border: 2px solid #40afe8;

            &:first-child {
              margin-right: 0.25rem;
            }

            &:nth-child(2) {
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }

            &:last-child {
              margin-left: 0.25rem;
            }

            .gobj {
              width: 90px;
              height: 90px;
              display: block;
              border-radius: 3px;
              position: relative;

              @media (min-width: $xs) {
                width: 120px;
                height: 120px;
              }

              @media (min-width: $s) {
                width: 130px;
                height: 130px;
              }

              @media (min-width: $m) {
                width: 90px;
                height: 90px;
              }

              @media (min-width: $l) {
                width: 130px;
                height: 130px;
              }
            }
          }
        }

        .gauge-container > .gauge .dial,
        .gobj > .gauge .dial {
          stroke: #e3e3e3;
          stroke-width: 8;
          fill: rgba(0, 0, 0, 0);
        }

        .gauge-container > .gauge .value,
        .gobj > .gauge .value {
          stroke: #0acf97;
          stroke-width: 8;
          fill: rgba(255, 255, 255, 0);
        }

        .gauge-container > .gauge .value-text,
        .gobj > .gauge .value-text {
          fill: #62717a;
          font-family: sans-serif;
          font-weight: bold;
          font-size: 0.8em;
        }
      }
    }
  }

  .date {
    font-size: ms(1);
    font-style: italic;
  }

  p {
    font-size: ms(1);
    line-height: 1.5;

    &.login {
      margin-top: 0;
      font-size: ms(0);
    }
  }

  .button {
    @extend %button;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding: 0.5em 1.5em;
  }

  &.home {
    .button {
      font-size: ms(2);
    }
  }

  .text {
    > :first-child {
      margin-top: 0;
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include span(3 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(6 of 12);
    }
  }

  img {
    filter: drop-shadow(0px 0.25em 0.25em rgba(0, 0, 0, 0.25));
  }

  .arrow-wrapper {
    display: none;

    @media (min-width: $s) {
      display: block;
      margin-left: 1rem;
      transform: translateY(-20px);
    }
  }

  .main-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 825px) {
    .main-container {
      margin-top: 0px;
    }
  }

  .partners-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    min-height: 360px;
    text-align: left;
    padding: 20px;
  }
}

.intro-banner {
  position: relative;
  background-image: url("/assets/img/intro/intro-meeting.jpg");
  background-size: cover;
  background-position: center;
  color: rgb(242, 242, 242);
  overflow: hidden;
  padding: 20px 20px 40px;
  margin-bottom: 0px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(1, 1, 1, 0.4); /* Adjust the opacity as needed */
  z-index: 1;
}

.banner-text {
  position: relative;
  z-index: 2;
}

.intro-para {
  font-size: 18px;
}

.infographic-banner {
  margin-bottom: $section-spacing / 2 !important;
}

.flex-home {
  display: flex;
  flex-direction: row;

  .flex-home-child1 {
    flex-grow: 1;
    flex-basis: 50%;
    padding: 10px;
    text-align: left;
  }

  .flex-home-child2 {
    flex-grow: 1;
    flex-basis: 50%;
    padding: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .flex-home {
    display: flex;
    flex-direction: column;

    .flex-home-child1 {
      flex-basis: 100%;
      padding: 10px;
    }

    .flex-home-child2 {
      flex-basis: 100%;
      padding: 10px;
    }
  }
}

.splash-buttons {
  display: flex;
  justify-content: start;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;

  .disco-button {
    padding: 0.5em 1em;
    border-radius: 5px;
    background-color: #40afe8;
    border: 2px solid #40afe8;
    width: auto;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    white-space: nowrap;
  }

  .disco-button:hover {
    color: #40afe8;
    background-color: #fff;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
}
