.features {
  padding-top: $section-spacing;
  padding-bottom: $section-spacing;
  color: $colour--grey--darkest;

  .home & {
    background-color: $colour--grey--lightest;
    padding-bottom: $section-spacing;
  }

  ul {
    list-style-type: disc;
    margin-top: 1em;
  }

  .container {
    > ol {
      @extend %list-style-remove;
    }
  }

  .feature {
    @extend %clearfix;
    padding: 10px;

    + .feature {
      margin-top: $section-spacing;
    }
  }

  .text {
    p:first-child {
      font-size: ms(1);
      line-height: 1.5;
      margin-top: 0;
    }
  }

  h2,
  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(6 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(6 of 12);
    }
  }

  .avatar {
    margin-top: 1.5rem;

    @include layout($narrow);
    margin-left: gutter();
    margin-bottom: gutter();
    @include span(first 0.75 of 4);

    @media (min-width: $m) {
      @include layout($medium);
      margin-left: gutter();
      margin-bottom: gutter();
      @include span(first 0.75 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      margin-left: gutter();
      margin-bottom: gutter();
      @include span(first 0.75 of 6);
    }
  }

  blockquote {
    font-size: ms(-1);
    margin-top: 1.5rem;

    @include layout($narrow);
    margin-right: gutter();
    margin-bottom: gutter();
    @include span(last 3 of 4);

    @media (min-width: $m) {
      @include layout($medium);
      margin-right: gutter();
      margin-bottom: gutter();
      @include span(last 5 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      margin-right: gutter();
      margin-bottom: gutter();
      @include span(last 5 of 6);
    }

    p {
      color: $colour--primary--dark;
      font-style: normal;
    }

    cite {
      font-size: 1.1em;
      line-height: 1.5;
    }
  }

  .illustration {
    margin-top: 1em;

    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 6 of 6);
      margin-top: 0em;
      padding-top: 20px;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(last 6 of 12);
      padding: 0px;
    }

    img {
      @include pre(2 of 12);
      @include span(12 of 12);

      @media (min-width: $l) {
        @include layout($wide);
        @include pre(1 of 10);
        @include span(10 of 10);
      }
    }
  }

  li:nth-of-type(2n) {
    h2 {
      @media (min-width: $l) {
        @include layout($wide);
        @include pre(6 of 12);
      }
    }

    h2,
    .text {
      @media (min-width: $l) {
        @include layout($wide);
        @include span(last 6 of 12);
      }
    }

    .illustration {
      @media (min-width: $l) {
        @include layout($wide);
        @include span(6 of 12);
      }
    }
  }

  li:nth-of-type(3n) {
    .illustration {
      // @include pre(3 of 12);
      // @include span(6 of 12);

      img {
        @include pre(3 of 12);
        @include span(6 of 12);

        @media (min-width: $l) {
          @include layout($wide);
          @include pre(2 of 10);
          @include span(5 of 10);
        }
      }
    }
  }

  .mobile.mobile {
    // Chained for specificity
    box-shadow: none;
  }

  .mobile {
    filter: drop-shadow(0px 0px 1em rgba(0, 0, 0, 0.1));
  }

  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;

    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }

  .columns > div {
    flex-basis: calc(33.33% - 40px); /* Adjust padding */
    padding: 20px 20px; /* Adjust padding */
    box-sizing: border-box;

    @media only screen and (max-width: 1100px) {
      flex-basis: 100% !important;
    }
  }
}

.columns-training {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.columns-training > div {
  flex-basis: calc(33.33% - 40px); /* Adjust padding */
  padding: 50px 20px; /* Adjust padding */
  box-sizing: border-box;

  @media only screen and (max-width: 1100px) {
    flex-basis: 100% !important;
  }
}

.speaker-cols {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.training-customers {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
}
