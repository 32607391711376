// Breakpoints
$xs: 25em;
$s: 31em;
$m: 43em;
$l: 60em;
$xl: 85em;

// Colours
$colour--primary: #c03c67;
$colour--primary--lightest: #f8ebef;
$colour--primary--darker: #aa214f;
$colour--primary--dark: #4b174d;
$colour--secondary: #fcb34b;
$colour--tertiary: #629139;
$colour--black: #3b3840;
$colour--grey--lightest: #f5f5f5;
$colour--grey--lighter: #d0d0d0;
$colour--grey: #ababab;
$colour--grey--darker: #605757;
$colour--grey--darkerer: #2f3b49;
$colour--grey--darkest: #3b3840;
$colour--grey--darkestest: #26232b;
$colour--white: #ffffff;
$colour--quaternary: #0a5b98;
$colour--quaternary--lighter: #40afe8;

// Layout
$section-spacing: 3.5em;

// Other
$border-radius: 0.3em;

// Tables -- Bootstap 4.6
$spacer: 1rem !default;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);
$text-muted: $gray-600 !default;
$body-color: $gray-900 !default;
$table-color: $body-color !default;
$table-bg: null !default;
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;
$border-width: 1px !default;
$table-border-width: $border-width !default;
$border-color: $gray-300 !default;
$table-border-color: $border-color !default;
$table-striped-order: odd !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;
$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;
$table-th-font-weight: null !default;
$table-dark-color: $white !default;
$table-dark-bg: $gray-800 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;
$table-caption-color: $text-muted !default;
$table-bg-level: -9 !default;
$table-border-level: -6 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
