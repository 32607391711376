.benefit {
  .icon {
    @include layout($medium);
    @include pre(2 of 6);
    @include span(2 of 6);
    @include post(2 of 6);

    text-align: center;

    // @media (min-width: $l) {
    //   @include layout($wide);
    //   @include pre(2 of 6);
    //   @include span(2 of 6);
    //   @include post(2 of 6);
    // }
  }

  // .text {

  //   @media (min-width: $l) {
  //     @include layout($wide);
  //     @include span(last 4 of 6);
  //   }
  // }

  svg {
    margin: 0 auto;
  }

  h2 {
    font-size: 1.4em;
    margin-top: 0;
    text-align: center;
  }

  p {
    font-size: 1.1em;
    line-height: 1.5;
    text-align: center;
  }
}
