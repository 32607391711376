.article {
  @extend %container;

  + iframe {
    margin-top: $section-spacing/2;
  }

  @media (min-width: $l) {
    @include layout($wide);
    @include pre(2 of 12);
    width: span(8 of 12);
  }
}
