.image-wrap-okr {
  float: left;
  max-width: 600px;
  width: 100%;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 25px;

  img {
    width: 100%;
    height: auto;
    max-width: 600px;
  }
}

@media screen and (max-width: 1000px) {
  .image-wrap-okr {
    width: 100%;
    float: none;
    text-align: center;

    img {
      padding-bottom: 40px;
      width: 100%;
    }
  }
}

.image-wrap-okr-right {
  float: right;
  max-width: 600px;
  width: 100%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 25px;

  img {
    width: 100%;
    height: auto;
    max-width: 600px;
  }
}

.vis-obj-kr-div {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  padding-top: 20px;

  .vis-obj-kr-child {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 20px;
    flex: 1;
    color: white;
    border-radius: 20px;
    text-align: left;
    transition: transform 300ms ease-in-out;
  }

  .vis-obj-kr-child:hover {
    transform: scale(1.05);
  }
}
