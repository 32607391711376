.testimonial {
  @extend %panel--light;
  background-color: $colour--grey--lightest;

  > .container {
    @extend %clearfix;
  }

  h2 {
    @media (min-width: $m) {
      text-align: center;
    }
  }

  .text {
    @media (min-width: $l) {
      @include layout($wide);
      @include span(first 6 of 12);
    }
  }

  h2 {
    @media (min-width: $s) {
      margin-top: 0;
    }
  }
}
