.pricing-tier-item {
  background-color: $colour--white;
  padding: 1em 1em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: $border-radius;

  ul {
    list-style-type: "\2714";
    margin-top: 1em;
  }

  li {
    padding-left: .5em;

    + li {
      margin-top: .25em;
    }
  }

  p {
    margin-top: .5em;
  }

  .user-group {
    font-style: italic;
  }

  .price {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .number {
    font-size: ms(3);
    font-weight: 600;
    margin-right: .1em;

    @media (min-width: $l) {
      font-size: ms(4);
    }
  }

  .button {
    margin-top: 1em;
    text-align: center;
    @extend %button;
    align-self: flex-start;
  }

  &.most-popular {

    .button {
      @extend %button--feature;
      font-size: ms(1);
    }
  }

  .most-popular-marker {
    display: block;
    background-color: $colour--quaternary--lighter;
    line-height: 1;
    color: $colour--white;
    font-weight: 600;
    margin: -1em -1em 1em -1em;
    padding: 1em;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}
