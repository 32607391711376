.blog-categories {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.blog-listing,
.partners-listing {
  @extend %list-style-remove;
  margin-top: $section-spacing;

  [role="complementary"] & {
    margin-top: 0;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.75em 1.2em;

  @media (min-width: $s) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $l) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.customer-listing {
  @extend %list-style-remove;
  margin-top: $section-spacing;

  [role="complementary"] & {
    margin-top: 0;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;

  @media (min-width: $m) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $l) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.more-posts {
  @extend %panel;

  h2,
  h3 {
    text-align: left;
  }

  .blog-list-item {
    @media (min-width: $l) {
      margin-bottom: 0;
    }
  }

  main &:last-child {
    padding-bottom: 0;
  }
}
