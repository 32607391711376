.google-reviews {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='752' preserveAspectRatio='none' viewBox='0 0 1440 752'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1009%26quot%3b)' fill='none'%3e%3crect width='1440' height='752' x='0' y='0' fill='rgba(245%2c 245%2c 245%2c 1)'%3e%3c/rect%3e%3cpath d='M 0%2c249 C 144%2c221.6 432%2c121.2 720%2c112 C 1008%2c102.8 1296%2c184.8 1440%2c203L1440 752L0 752z' fill='rgba(255%2c 255%2c 255%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c681 C 144%2c641 432%2c479.2 720%2c481 C 1008%2c482.8 1296%2c648.2 1440%2c690L1440 752L0 752z' fill='rgba(245%2c 245%2c 245%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1009'%3e%3crect width='1440' height='752' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-size: cover;
  padding: 3.5rem 0 4.5rem 0;
  color: #3b3840;

  h2 {
    margin-top: 0;
    text-align: center;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .carousel-cell {
    width: 100%;
    height: 460px;
    margin-right: 18px;
    // background: #ededed;
    border-radius: 0.25rem;
    counter-increment: carousel-cell;

    @media only screen and (max-width: 900px) {
      height: 440px;
    }

    @media only screen and (max-width: 680px) {
      height: 420px;
    }

    @media only screen and (max-width: 500px) {
      height: 400px;
    }

    &:before {
      display: block;
      text-align: center;
      line-height: 200px;
      font-size: 80px;
      color: #ffffff;
    }

    .carousel-logo {
      height: 50px;
      width: auto;
    }

    .carousel-cell-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      text-align: center;
      width: 80%;
      margin: 0 auto;

      .reviewer {
        margin-top: 5px;
        // margin: 0;
        font-size: 18px;
        font-weight: bold;
        @media only screen and (max-width: 900px) {
          font-size: 16px;
        }

        @media only screen and (max-width: 680px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      .reviewer-image-div {
        border-radius: 50%;
        width: 75px;
        height: 75px;
        overflow: hidden;

        @media only screen and (max-width: 900px) {
          width: 70px;
          height: 70px;
        }

        @media only screen and (max-width: 680px) {
          width: 65px;
          height: 65px;
        }

        @media only screen and (max-width: 500px) {
          width: 60px;
          height: 60px;
        }
      }

      .reviewer-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding-left: 5px;
      }

      .reviewer-business {
        margin: 0;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (max-width: 900px) {
          font-size: 16px;
        }

        @media only screen and (max-width: 680px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      .edge-logo {
        height: 100px;
        width: auto;
        @media only screen and (max-width: 500px) {
          height: 75px;
          width: auto;
        }
      }

      .google-stars {
        position: relative;
        background-color: transparent;
        top: 0;
      }

      .review-comment {
        @media only screen and (max-width: 900px) {
          max-height: 250px;
          font-size: 16px;
          padding: 20px 4px;
        }

        @media only screen and (max-width: 680px) {
          max-height: 240px;
          font-size: 14px;
          padding: 15px 4px;
        }

        @media only screen and (max-width: 500px) {
          max-height: 230px;
          font-size: 14px;
          padding: 10px 4px;
        }

        padding: 30px 4px 50px 4px;
        margin-top: 1em;
        overflow-y: scroll;
        font-size: 1.25em;

        p {
          margin-top: 0;
        }
      }
    }
  }
}
.reviews-header {
  @media only screen and (max-width: 900px) {
    font-size: 2.25em;
    padding: 40px 10px;
  }

  @media only screen and (max-width: 680px) {
    font-size: 2em;
    padding: 30px 10px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.75em;
    padding: 25px 10px;
  }
  padding: 50px 10px;
  font-size: 2.5em;
  font-weight: 500;
  text-align: center;
  color: #3b3840;
}
