.row:after {
  content: "";
  display: table;
  clear: both;
}

.partner-text {
  color: rgba(85, 104, 125);
  font-weight: 200;
}

.partner-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 15px;
}

.log-in-link {
  cursor: pointer;
}

.partner-testimonials {
  background-color: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 50px;
  margin: 10px;
  color: #55687d;
  flex: 1 1 0;
  border: 2px solid rgb(219, 219, 219);
}

.testimonial-parent {
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.benefits-div {
  width: 33%;
  padding: 20px;
  text-align: center;

  @media screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.partner-div {
  display: flex;
  flex-direction: column;
  width: 33%;
  padding: 20px;
  align-items: center;

  @media screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.introducer-benefit-div {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: start;
  border: 2px solid #4b174db0;
  border-radius: 15px;
  flex: 1;
  box-shadow: 5px 3px 3px #8a8a8a40;
  transition: 0.3s;

  @media screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.introducer-benefit-div:hover {
  transform: translate(0, -10px);
}

.introducer-hiw-parent {
  flex-direction: row;
  display: flex;

  @media screen and (max-width: 850px) {
    flex-direction: column !important;
  }
}

.introducer-hiw-col {
  width: 50%;
  padding: 0 10px;

  @media screen and (max-width: 850px) {
    width: 100% !important;
  }
}

.referral-title {
  padding-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #3b3840;
  text-align: center;
}

.referral-text {
  padding-bottom: 10px;
  text-align: center;
}

.partner-icon-div {
  padding-bottom: 15px;
}

.gradient {
  background: -webkit-linear-gradient(225deg, #c03c67, #4b174d) 0% 0% / 300%
    300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 10s ease 0s infinite normal none running fontgradient;
  -webkit-animation: fontgradient 10s ease infinite;
  -moz-animation: fontgradient 10s ease infinite;
  animation: fontgradient 10s ease infinite;
}
@-webkit-keyframes fontgradient {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@-moz-keyframes fontgradient {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}
@keyframes fontgradient {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

.pink-icon {
  color: #c03c67;
}

.purple-icon {
  color: #4b174d;
}

.partner-button {
  border-radius: 6px;
  background-color: #4b174d;
  border: 0px;
  color: white;
  font-size: 1.1em;
  padding: 15px;
}

.partner-button:hover {
  filter: brightness(140%);
  cursor: pointer;
}

.partner-with-reclaro-div {
  @media screen and (max-width: 1020px) {
    width: 100% !important;
  }

  width: 50%;
}

.staff-with-reclaro-div {
  @media screen and (max-width: 900px) {
    width: 100% !important;
  }

  width: 50%;
}

.float-div-left {
  @media screen and (max-width: 650px) {
    padding: 0px !important;
  }

  float: left;
  padding-right: 20px;
}

.float-div-right {
  @media screen and (max-width: 650px) {
    padding: 0px !important;
  }
  float: right;
  padding-left: 20px;
}

.float-div-left-training {
  @media screen and (max-width: 650px) {
    padding: 0px !important;
  }

  float: left;
  padding-right: 20px;
}

.float-div-right-training {
  @media screen and (max-width: 650px) {
    padding: 20px !important;
  }
  float: right;
  padding-left: 20px;
}

.partners-benefits {
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
}

.introducer-benefits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.get-in-touch {
  padding-top: 80px;

  @media only screen and (max-width: 825px) {
    padding-top: 0px !important;
  }
}

.types-of-partnership-title {
  @media only screen and (max-width: 825px) {
    padding-top: 0px !important;
  }
}

.partnership-title {
  color: #3b3840;
  font-size: 2.5em;
  font-weight: 400;
  text-align: center;
  padding-bottom: 40px;
}

.partnerships-parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .partnership-div {
    width: 30%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 750px) {
      width: 100% !important;
      border: 0px;
      margin: 0px;
      height: 200px;
    }

    .partnership-image-div {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;

      .image-div {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1170px) {
          width: 200px;
        }
      }
      .image-div:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
    }
  }
}

#clockdiv {
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
}

#clockdiv > div {
  padding: 10px;
  border-radius: 3px;
  background: #4b174d90;
  display: inline-block;
  width: 84px;
}

#clockdiv div > span {
  padding: 12px;
  border-radius: 3px;
  background: #4b174d;
  display: inline-block;
  width: 66px;
}

.smalltext {
  padding-top: 5px;
  font-size: 16px;
}
