.contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & footer {
    margin-top: auto !important;
  }

  & p {
    text-align: center;
    margin-bottom: 20px;
  }

  & h1 {
    text-align: center;
  }
}
