.blog-post {
  h1 {
    @media (min-width: $l) {
      @include layout($wide);
      //     max-width: span(8 of 12);
      text-align: left;
    }
  }

  [role="main"] {
    @extend %clearfix;

    > .container {
    }
  }

  .description {
    text-align: left;
  }

  .more-posts {
    clear: both;
  }

  .article {
    font-size: ms(1);
    padding-left: 0;
    padding-right: 0;

    p:not(.author, .date) {
      margin-bottom: $section-spacing/2;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(8 of 12);
      margin-left: 0;
    }
  }

  .call-to-action {
    @media (min-width: $l) {
      @include layout($wide);
      @include span(last 4 of 12);
      margin-top: 0;
    }
  }

  .hero-image {
    margin-top: 0;

    @media (min-width: $l) {
      margin-left: 0;
      width: 100%;
      float: none;
    }
  }

  .lead-cold,
  .lead-hot {
    margin-left: -0.5em;
    margin-right: -0.5em;

    @media (min-width: $l) {
      margin-left: 0;
      margin-right: 0;
    }

    .gradient-robot {
      font-size: 75px;
      padding-bottom: 15px;
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      background-image: linear-gradient(
        45deg,
        var(--myColor1),
        var(--myColor2)
      );
      transition: --myColor1 0.5s, --myColor2 0.5s;

      &:hover {
        --myColor1: #d84375;
        --myColor2: #45bdf9;
      }

      @media only screen and (max-width: 600px) {
        .gradient-robot {
          font-size: 60px;
        }
      }
    }

    .container {
      @media (min-width: $l) {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }

  main .call-to-action:last-child {
    margin-bottom: 0;
  }

  .cta-checklist {
    @extend %clearfix;
    .illustration {
      @include span(2 of 12);
      margin-top: $section-spacing/2;
    }

    form {
      @include span(9 of 12);
      max-width: span(8 of 12);
      margin-left: 0;

      ._form_element {
        margin-bottom: 1em;
      }

      button {
        font-size: ms(0);
        margin-top: 1em;
      }

      .privacy-statement {
        margin-top: 0.5em;
      }
    }
  }

  .avatar {
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-width: 800px) {
  .partners-image-calendly-div {
    flex-direction: column-reverse !important;
  }
  .partners-calendly-widget {
    iframe {
      min-height: 500px;
    }
  }
}

.partners-page-widget {
  iframe {
    height: 650px;
  }
}
