.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
}

#cookies-eu-banner-content {
  position: relative;
  max-width: 500px;
  margin: 10% auto;
  background: $colour--primary--dark;
  color: #fff;
  padding: 1.25rem;
  font-size: 18px;
  text-align: left;
  box-shadow: 0px 0px 20px #555;
  border-radius: 0.25em;

  h3 {
    margin-top: 0;
  }
}

#cookies-eu-accept {
  display: block;
  width: 100%;
  background: #fff;
  color: #222;
  border: 1px solid #000;
  border-radius: 0.25em;
  cursor: pointer;
  margin: 10px 0px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  transition: background 0.25s, color 0.25s, border-color 0.25s;
}

#cookies-eu-accept:hover,
#cookies-eu-accept:focus {
  background: #222;
  color: #fff;
}

#cookies-eu-accept {
  padding: 5px 20px;
}

#cookies-eu-reject,
#cookies-eu-more {
  display: block;
  background-color: transparent;
  margin: 12px 0px 0px 0px;
  padding: 0;
  border: none;
  color: #ccc;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
