.profile {
  @extend %clearfix;

  padding-top: 20px;

  h3 {
    font-weight: 400;

    a {
      &:link,
      &:visited {
        color: $colour--black;
      }
    }
  }

  .position {
    font-style: italic;
    margin-top: -0.5em;

    + p {
      margin-top: 0;
    }
  }

  .social-link {
    margin-top: 0.5em;
  }

  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 2 of 3);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(last 3 of 4);
    }
  }

  h3 {
    margin-top: 0.5em;
    font-size: ms(1);

    @media (min-width: $xs) {
      margin-top: 0;
    }
  }

  .avatar {
    @include layout($narrow);
    max-width: span(2 of 4);

    @media (min-width: $xs) {
      @include layout($narrow);
      @include span(1 of 4);
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include span(1 of 3);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(1 of 4);
    }
  }
}
