.free-action-book {
  @extend %clearfix;

  img {
    @media (min-width: $xs) {
      @include layout($narrow);
      @include span(2 of 4);
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include pre(1 of 6);
      @include span(2 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include pre(2 of 12);
      @include span(3 of 12);
    }
  }

  p {

    @media (min-width: $m) {
      @include layout($medium);
      @include span(2 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(5 of 12);
    }
  }

  form {
    clear: both;

    ._form_element {
      margin-bottom: 1em;
    }

    button {
      font-size: ms(0);
      margin-top: 1em;
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include pre(1 of 6);
      @include span(4 of 6);
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include pre(2 of 12);
      @include span(8 of 12);
      max-width: none;
    }
  }

  .privacy-statement {
    margin-top: 0.5em;
  }
}
