%gradient--horizontal {
  background: #c03c67;
  background: linear-gradient(
    to right,
    rgba(192, 60, 103, 1) 0%,
    rgba(178, 55, 100, 1) 18%,
    rgba(153, 47, 94, 1) 33%,
    rgba(132, 41, 90, 1) 51%,
    rgba(107, 33, 84, 1) 68%,
    rgba(89, 27, 81, 1) 88%,
    rgba(75, 23, 77, 1) 100%
  );
}

%gradient--vertical {
  background: #c03c67;
  background: linear-gradient(
    to bottom,
    rgba(192, 60, 103, 1) 0%,
    rgba(178, 55, 100, 1) 18%,
    rgba(153, 47, 94, 1) 33%,
    rgba(132, 41, 90, 1) 51%,
    rgba(107, 33, 84, 1) 68%,
    rgba(89, 27, 81, 1) 88%,
    rgba(75, 23, 77, 1) 100%
  );
}

%panel {
  padding-top: $section-spacing;
  padding-bottom: $section-spacing;

  h2 {
    margin-top: 0;
  }
}

%panel--light {
  @extend %panel;
  // background-color: $colour--primary--lightest;
}

%panel--black {
  @extend %panel;
  background: $colour--grey--darkerer;
  color: $colour--white;
}

%clearfix {
  @include clearfix;
}

%list-style-remove {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

%horizontal-list {
  @extend %list-style-remove;

  @media (min-width: $l) {
    li + li {
      margin-top: 0;
    }
  }
}

%debug {
  outline: 1px solid blue;
}

// can change buttons here
%button {
  padding: 0.5em 1em;
  border-radius: 0;
  background-color: $colour--quaternary--lighter;
  //   border: 0;
  border: 2px solid #40afe8;
  border-radius: 5px;
  width: auto;
  color: $colour--white;
  text-decoration: none;
  display: inline-block;
  transition: 0.3s;

  &:focus {
    // box-shadow: 0 0 0 3px $colour--primary;
    scale: 0.95;
    outline: none;
  }

  &:visited {
    color: $colour--white;
  }

  &:hover,
  &:active,
  &.button--active,
  &:focus {
    filter: brightness(110%);
  }
}

%button--inverted {
  @extend %button;
  background-color: $colour--primary--dark;
  border-color: $colour--primary--dark;
  transition: 0.3s;

  //   &:visited {
  //     color: $colour--white;
  //   }

  &:hover,
  &:active,
  &.button--active,
  &:focus {
    filter: brightness(150%);
  }
}

// increase container max-width to fill more of screen
%container {
  max-width: 90vw;
  //   width: 95em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
