.testimonials {
  h2 {
    text-align: center;
  }

  .customer-stories-testimonial {
    @extend %clearfix;
    border-radius: 15px;
    border: 2px solid #e6e6e6;
    margin: 10px;
    padding-right: 10px;
    padding-left: 10px;

    .text {
      font-size: ms(0);
      font-weight: 300;
      margin-top: 1.5rem;

      @include layout($narrow);
      margin-right: gutter();
      margin-bottom: gutter();
      @include span(last 3 of 4);

      @media (min-width: $m) {
        @include layout($medium);
        margin-right: gutter();
        margin-bottom: gutter();
        @include span(last 5 of 6);
      }

      .position,
      .company {
        display: block;
      }

      .position {
        color: $colour--grey;
        font-style: italic;
      }

      .company {
        color: $colour--quaternary--lighter;
      }
    }

    .avatar {
      margin-top: 3rem;

      @include layout($narrow);
      margin-left: gutter();
      margin-bottom: gutter();
      @include span(first 0.75 of 4);

      @media (min-width: $m) {
        @include layout($medium);
        margin-left: gutter();
        margin-bottom: gutter();
        @include span(first 0.75 of 6);
      }
    }

    &:nth-of-type(2n) {
      .text {
        @include layout($narrow);
        margin-right: gutter();
        margin-bottom: gutter();
        @include span(3 of 4);

        @media (min-width: $m) {
          @include layout($medium);
          margin-right: gutter();
          margin-bottom: gutter();
          @include span(5 of 6);
        }
      }

      .avatar {
        @include layout($narrow);
        margin-left: gutter();
        margin-bottom: gutter();
        @include span(last 0.75 of 4);

        @media (min-width: $m) {
          @include layout($medium);
          margin-left: gutter();
          margin-bottom: gutter();
          @include span(last 0.75 of 6);
        }
      }
    }
  }
}
