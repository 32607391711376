.clients {
  @extend %panel;
  padding: 80px 0;
  background-color: $colour--grey--lightest;

  h2 {
    @media (min-width: $m) {
      text-align: center;
    }
  }

  .container {
    @extend %clearfix;
  }
}

.client-logos {
  @extend %list-style-remove;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-bottom: $section-spacing/3;
    box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.1);
    @include layout($narrow);
    @include span(2 of 4);

    &:nth-of-type(2n) {
      @include layout($narrow);
      @include span(last 2 of 4);
    }

    @media (min-width: $s) {
      @include layout($medium);
      @include span(2 of 8);

      &:nth-of-type(2n) {
        @include layout($medium);
        @include span(2 of 8);
      }

      &:nth-of-type(4n) {
        @include layout($medium);
        @include span(last 2 of 8);
      }

      &:nth-of-type(5n) {
        clear: both;
      }
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(2 of 12);

      &:nth-of-type(4n + 1) {
        @include layout($wide);
        @include pre(2 of 12);
        @include span(2 of 12);
      }

      &:nth-of-type(2n) {
        @include layout($wide);
        @include span(2 of 12);
      }

      &:nth-of-type(4n) {
        @include layout($wide);
        @include span(2 of 12);
      }

      &:nth-of-type(5n) {
        clear: both;
      }
    }
  }

  .client-logo:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}

// @keyframes slide {
//   from {
//     transform: translateX(0);
//   }
//   to {
//     transform: translateX(-100%);
//   }
// }
// .logos {
//   overflow: hidden;
//   padding: 60px 0;
//   background: white;
//   white-space: nowrap;
//   position: relative;
// }

// .logos:before,
// .logos:after {
//   position: absolute;
//   top: 0;
//   width: 250px;
//   height: 100%;
//   content: "";
//   z-index: 2;
// }

// .logos:before {
//   left: 0;
//   background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
// }

// .logos:after {
//   right: 0;
//   background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
// }

// .logos:hover .logos-slide {
//   animation-play-state: paused;
// }

// .logos-slide {
//   //   display: inline-block;
//   display: flex;
//   animation: 25s slide infinite linear;
// }

// .logos-slide img {
//   height: 50px;
//   margin: 0 40px;
// }
