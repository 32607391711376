.video {
  margin-bottom: $section-spacing;
  margin-top: $section-spacing;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.1);
  background-color: transparent;

  @media (min-width: $l) {
    @include layout($wide);
    @include pre(2 of 12);
    width: span(8 of 12);
  }
}

.explainer-video {
  padding-top: $section-spacing;
  padding-bottom: $section-spacing;
  background-color: $colour--white;
}

.home-video {
  @media (min-width: $l) {
    @include layout($wide);
    @include pre(2 of 12);
    width: span(8 of 12);
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
