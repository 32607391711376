.social-link {
  margin-top: 1em;

  a {
    display: flex;
    align-items: flex-end;
  }

  .icon-linkedin {
    margin-right: .5em;
    display: inline-block;
  }
}
