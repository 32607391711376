[role="navigation"] {
  font-family: "Inter", sans-serif;
  color: $colour--primary--dark;
  border-bottom: 1px solid $colour--grey--lightest;
  // padding: 1rem 1rem 0rem 1rem;
  padding: 0;
  position: relative;
  z-index: 3;

  // @media (min-width: $xs) {
  //   padding: 1rem 0.5rem;
  // }

  // @media (min-width: $s) {
  //   padding: 1.5rem;
  // }

  > .container {
    // @media (min-width: $m) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 90px;
    // text-align: right; // no-flexbox fallback
    // }
  }

  a {
    color: $colour--primary--dark;
  }
}

.logo {
  padding: 0.5rem;
  order: 1;
  text-decoration: none;

  // @media (min-width: $xs) {
  //   display: flex;
  //   align-items: flex-end;
  // }

  // a {
  //   color: $colour--primary;
  // }

  // .strapline {
  //   display: block;
  //   font-size: ms(0);
  //   margin-top: 0;
  //   color: $colour--secondary;

  //   @media (min-width: $xs) {
  //     display: inline-block;   // no-flexbox fallback
  //     margin-left: .75em;
  //     margin-bottom: -.1em;
  //   }
  // }

  // @media (min-width: $l) {
  //   float: left;  // no-flexbox fallback
  // }
}

.nav-menus {
  font-size: ms(0);
  // display: inline-block;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300;
  height: 90px;
  line-height: 1;

  &.menus--account {
    @media only screen and (max-width: 760px) {
      display: none;
    }
    order: 2;
    @media (min-width: $m) {
      order: 3;
    }
  }

  &.menus--site {
    order: 3;
    @media (min-width: $m) {
      order: 2;
    }

    @media only screen and (max-width: 760px) {
      display: none;
    }
  }

  &.mobile-menus--site {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: end;

    i {
      font-size: 20px;
    }
    @media only screen and (min-width: 761px) {
      display: none;
    }
  }

  ul {
    @extend %list-style-remove;
    // display: inline-block; // no-flexbox fallback
    display: flex;
    height: 100%;
    // margin-top: 1em;

    @media (min-width: $l) {
      margin-top: 0;
    }
  }

  li {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 0.5rem;

    @media (min-width: $l) {
      margin: 0 1.2rem;
    }
  }

  li.dropdown {
    cursor: pointer;
    // white-space: nowrap;

    ul.dropdown-content {
      background-color: #fff;
      border-bottom-left-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      display: none;
      height: auto;
      left: 0;
      margin-top: 0;
      overflow: scroll;
      position: absolute;
      text-align: left;
      top: 170px;
      width: 300px;
      z-index: 3;

      @media (max-width: 375px) {
        top: 280px !important;
      }

      @media (min-width: $m) {
        left: unset;
        max-width: 500px;
        top: 146px;
        width: 300px;
      }

      @media (max-width: $m) {
        top: 220px;
      }

      @media (min-width: 760px) {
        top: 180px;
      }

      @media (min-width: 791px) {
        top: 140px;
      }

      li {
        color: $colour--primary--dark;
        display: block;
        line-height: 1;
        // margin: 1.2rem;
        padding: 20px;
        margin: 0;
        // transition: all 0.2s ease-in-out;

        &:hover {
          color: $colour--primary;
          background-color: rgb(243, 243, 243);
        }

        a {
          color: inherit;
          padding: 0.3em;
          display: block;
        }
      }
    }

    ul.dropdown-content.show {
      display: block;
    }
  }

  li.dropdown2 {
    cursor: pointer;
    // white-space: nowrap;

    ul.dropdown-content2 {
      background-color: #fff;
      border-bottom-left-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      display: none;
      height: auto;
      left: 0;
      margin-top: 0;
      overflow: scroll;
      position: absolute;
      text-align: left;
      top: 170px;
      width: 300px;
      z-index: 3;

      @media (max-width: 375px) {
        top: 280px !important;
      }

      @media (min-width: $m) {
        left: unset;
        max-width: 500px;
        top: 146px;
        width: 300px;
      }

      @media (max-width: $m) {
        top: 220px;
      }

      @media (min-width: 760px) {
        top: 180px;
      }

      @media (min-width: 791px) {
        top: 140px;
      }

      li {
        color: $colour--primary--dark;
        display: block;
        line-height: 1;
        // margin: 1.2rem;
        padding: 20px;
        margin: 0;
        // transition: all 0.2s ease-in-out;

        &:hover {
          color: $colour--primary;
          background-color: rgb(243, 243, 243);
        }

        a {
          color: inherit;
          padding: 0.3em;
          display: block;
        }
      }
    }

    ul.dropdown-content2.show {
      display: block;
    }
  }
}

.nav-site {
  // #nav-item-features {
  //   padding-left: 0;
  // }

  a,
  span {
    padding: 0.3rem 0;
    text-decoration: none;

    &.menu-item-current {
      border-bottom: 1px solid $colour--primary;
    }

    @media (min-width: $m) {
      padding: 0.5rem 0;
    }

    // @media (min-width: $m) {
    //   padding: 0 .5rem;
    // }

    &:hover,
    &:active,
    &:focus {
      color: $colour--primary;
    }
  }
}

.nav-account {
  li {
    line-height: 1.5;
    margin-right: 0;
    margin-left: 0;

    @media (min-width: $l) {
      margin-left: 0.5em;
    }

    + li {
      margin-left: 0.5em;

      @media (min-width: $s) {
        margin-left: 1em;
      }
    }

    a {
      @extend %button;
      font-weight: 400;
      padding: 0.5em;

      @media (min-width: $s) {
        padding: 0.5em 1.5em;
      }
    }

    &:last-of-type {
      a {
        @extend %button--inverted;
        // padding: .5rem 1.5rem;
        padding: 0.5em;

        @media (min-width: $s) {
          padding: 0.5em 1.5em;
        }
      }
    }
  }
}

// .nav-small {
//   display: none;

//   div.dropdown-content {
//     position: absolute;
//     background-color: $colour--primary--dark;
//     margin-top: 0;
//     width: 100%;
//     text-align: left;
//     overflow: auto;
//     box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
//     z-index: 1;

//     @media (min-width: $s) {
//       padding: 0 1.5rem;
//     }

//     ul {
//       display: block;
//       padding: 0;
//       list-style-type: none;

//       @media (min-width: $m) {
//         display: flex;
//         justify-content: space-between;
//         justify-content: space-around;
//       }

//       li {
//         margin: 0 0.5em;
//         text-align: center;

//         a {
//           color: $colour--white;
//           padding: 1em 0em;
//           display: block;
//           text-decoration: none;

//           transition: linear 0.1s;

//           &:hover,
//           &:active,
//           &:focus {
//             color: $colour--primary
//           }
//         }
//       }
//     }
//   }

//   &.show {
//     display: block;
//   }
// }

.mobile-nav-link {
  font-size: 15px;

  @media only screen and (max-width: 450px) {
    font-size: 0.8em;
  }
}

.mobile-nav-div {
  @media only screen and (min-width: 761px) {
    display: none;
  }

  position: fixed;
  right: -100%;
  top: 140px;
  width: 100%;
  height: calc(100% - 140px);
  background-color: #2f3b49;
  transition: all 400ms;
  overflow-y: scroll;

  .mobile-nav-list {
    list-style-type: none;
    padding-left: 0;

    .mobile-nav-list-item {
      padding: 10px 45px;
      white-space: nowrap;
      font-size: 20px;

      a {
        text-decoration: none;
        color: white;
      }
    }

    .mobile-nav-list-item:hover {
      background-color: #3f4f62;
    }
  }

  .nav-account-mobile {
    padding: 20px;
    font-size: 20px;
    margin: 0px 25px;
    gap: 20px;

    .book-demo {
      background-color: $colour--quaternary--lighter;
      padding: 10px;
      display: inline-block;
      margin-bottom: 10px;
      border-radius: 5px;

      a {
        text-decoration: none;
        color: white;
        text-align: center;
      }

      &:hover {
        opacity: 95%;
      }
    }

    .sign-in {
      background-color: $colour--primary--dark;
      padding: 10px;
      display: inline-block;
      border-radius: 5px;

      a {
        text-decoration: none;
        color: white;
      }

      &:hover {
        opacity: 95%;
      }
    }
  }
}

.nav-active {
  right: 0;
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #3b3840;

  h2 {
    margin-top: 10px;
  }
}

.popup-content {
  @media only screen and (max-width: 700px) {
    width: 90%;
  }
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 600px;
  margin: auto;

  p {
    font-size: 22px;
  }

  .signup-button {
    padding: 0.5em 1em;
    border-radius: 5px;
    background-color: #40afe8;
    border: 2px solid #40afe8;
    width: auto;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    white-space: nowrap;
    margin-top: 20px;
    font-size: 20px;
  }

  .signup-button:hover {
    color: #40afe8;
    background-color: #fff;
    cursor: pointer;
  }
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
