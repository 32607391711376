.featured-in {
  padding: 75px 0px;

  .header {
    text-align: center;
  }

  .feature-div {
    width: 18%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1170px) {
      width: 32%;
    }

    @media screen and (max-width: 750px) {
      width: 100% !important;
      border: 0px;
      margin: 0px;
      height: 200px;
    }

    .feature-image-div {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;

      .image-div {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1170px) {
          width: 200px;
        }
      }
      .image-div:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
    }
  }

  @mixin white-gradient {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  $animationSpeed: 20s;

  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  // Styling
  .home-slider {
    background: white;
    // box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    max-width: 1750px;
    width: 90vw;

    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 100px;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(250px * 14);
    }

    .slide {
      width: 250px;
      height: 100px;
      display: flex;
      align-items: center;

      img {
        margin: auto;
        padding: 0 20px;
      }
    }
  }
}
