.faq-item {
  background-color: #f2f2f2;
  border-radius: $border-radius;
  padding: 1em 1em 1.5em;


  dt {
    font-weight: 600;
    font-size: ms(1);
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: .5em;

    @media (min-width: $l) {
      font-size: ms(2);
    }
  }

  dd {
    margin-left: 0;
  }
}
